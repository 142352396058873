@import 'fonts.scss';

.fileInput {
  width: 200px;
  height: 166px;
  border: none !important;
}

.imageInput1920x1080 {
  width: 384px;
  height: 216px;
}

.imageInput1080x1920 {
  width: 216px;
  height: 384px;
}

.videoImageInput {
  width: 216px;
  height: 263px;
}

.hint {
  display: block;
  color: $warning;
  @include base-font;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  margin-top: 2px;
  width: 424px;
  a {
    @include base-font;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    margin-top: 2px;
  }
}

:global(textarea.form-control).linePushMessage {
  height: 100px;
}


.frequencyControl {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $dark;
  display: flex;
  flex-direction: row;

  :global(.errorTip) {
    margin-top: 5px;
    min-height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    width: 100%;
    display: block;
    color: $danger;
  }
  .maxFrequency, .intervalHours {
    width: 70px;
    margin: 0px 5px;
    &.error {
      border-color: $danger;
    }
  }

  .intervalHoursContainer {
    margin-right: 5px;
  }
}

.lineMessageOperates {
  margin-left: 0px;
}

.filterAdder {
  color: $theme1;
  @include base-font;
  font-size: 14px;
  cursor: pointer;
}
