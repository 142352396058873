@import 'components.scss';

.channelGroupMembers {
  height: 100%;
  display: flex;
  flex-direction: column;
  .operationPanel {
    background-color: $white;
    border-bottom: 1px solid $super-light;
    height: 66px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    > button {
      margin-right: 30px;
      @include createButton;
    }
  }
  .listContainer {
    @include list-container;
    > div {
      height: 100%;
    }
  }
}